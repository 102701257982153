import * as React from "react";
import { NotFoundIcon } from "components/Icons";
import { notFoundP } from "./NotFound.module.scss";

export const NotFound = () => (
  <>
    <NotFoundIcon />
    <p className={notFoundP}>PAGE NOT FOUND</p>
  </>
);
